<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
          <!-- <box-regular username="Controllers"></box-regular> -->
        </v-col>
      </v-row>
    </v-card>

    <div class="mx-6 my-6">
      <h1 class="title-heading">{{ $t("pallets.positioned") }}</h1>

      <div class="d-flex flex-wrap">
        <template v-for="(data, key) in boxStocks">
          <div class="col-3" :key="key">
            <div class="box">
              <div class="box-title">
                {{ locations[key].description }}
              </div>
              <h1 class="num" id="heading" v-if="boxStocks">
                {{ boxStocks[key].count }}
              </h1>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="mx-6 my-6">
      <h1 class="title-heading">{{ $t("pallets.in_transit") }}</h1>
      <div class="d-flex flex-wrap">
        <template v-for="(data, key) in transitPallets">
          <div class="col-3" :key="key">
            <div class="box">
              <div class="box-title">
                {{ locations[key].description }}
              </div>
              <h1 class="num" id="heading">
                {{ transitPallets[key].count }}
              </h1>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "pallets",
  async mounted() {
    await this.fetchLocation({ order_by: "id" }).then((data) =>
      this.fetchBoxStock(data)
    );
    await this.fetchLocation({ order_by: "id" }).then((data) =>
      this.fetchTransitPallets(data)
    );
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    ...mapActions({
      fetchLocation: "dashboard/fetchLocation",
      fetchBoxStock: "dashboard/fetchBoxStock",
      fetchTransitPallets: "dashboard/fetchTransitPallets",
    }),
    getBoxStock(area) {
      return this.boxStocks.find((x) => x.location_id === area.location);
    },
  },
  computed: {
    ...mapGetters({
      locations: "dashboard/getLocations",
      areasTransit: "dashboard/getAreaTransit",
      boxStocks: "dashboard/getBoxStock",
      transitPallets: "dashboard/getTransitPallets",
    }),
  },
};
</script>

<style></style>
